[
  {
    "zenbakia": 0,
    "hasi": "2024/02/10",
    "bukatu": "2024/02/19"
  },
  {
    "zenbakia": 1,
    "hasi": "2024/02/26",
    "bukatu": "2024/03/07"
  },
  {
    "zenbakia": 2,
    "hasi": "2024/03/08",
    "bukatu": "2024/04/25"
  },
  {
    "zenbakia": 3,
    "hasi": "2024/04/26",
    "bukatu": "2024/04/28"
  },
  {
    "zenbakia": 4,
    "hasi": "2024/04/29",
    "bukatu": "2024/05/19"
  },
  {
    "zenbakia": 5,
    "hasi": "2024/05/20",
    "bukatu": "2024/05/20"
  },
  {
    "zenbakia": 6,
    "hasi": "2024/05/21",
    "bukatu": "2024/06/21"
  },
  {
    "zenbakia": 7,
    "hasi": "2024/06/22",
    "bukatu": "2099/02/10"
  }
]
