import AOS from 'aos';
import Swiper, { Navigation, Pagination, EffectCoverflow  } from 'swiper';
import { createPopper } from '@popperjs/core';
var sliderDatak = require('../_data/sliderDatak.json');

let showPopoverTimeout;
window.onload = function() {

  //Zerrenda animazioak
  AOS.init();

  document.getElementById("topBtn").addEventListener("click", function(event){
    goToTop();
  });

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      var panel = this.nextElementSibling;
      this.classList.toggle("active");
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      } 
    });

  }
  if(acc[0]) {
    acc[0].click();
  }

  var modalButtons = document.getElementsByClassName("modalaIreki");
  for (var i = 0; i < modalButtons.length; i++) {
    modalButtons[i].addEventListener("click", function(event) {
      let modalaId = event.target.getAttribute("modalaId")?? event.target.parentNode.getAttribute("modalaId");
      modalaIreki(modalaId);
    });
  }

  document.getElementById("modalaItxi").addEventListener("click", function(event) {
    modalaItxi();
  });

  window.addEventListener('click', function(event) {
    let targetEl = event.target; // clicked element      
    do {
      if (targetEl.classList?.contains("contModal") || targetEl.classList?.contains("modalaIreki")) {
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    modalaItxi();
  });

  var aurrekoBatzarrak = document.getElementsByClassName("aurrekoBatzarrak");
  for (var i = 0; i < aurrekoBatzarrak.length; i++) {
    aurrekoBatzarrak[i].addEventListener('mouseover', function(event) {
      var id = event.target.getAttribute("modalaId");
      createPopper(document.getElementById(id),  document.getElementById(id+"_popover"));
      showPopoverTimeout = setTimeout(() => {
        document.getElementById(id+"_popover").style.display = "";
        document.getElementById(id+"_popover").style.zIndex = "100";
        document.getElementById(id+"_popover").style.opacity = "1";
      }, 300);
    }, false);
  }
  for (var i = 0; i < aurrekoBatzarrak.length; i++) {
    aurrekoBatzarrak[i].addEventListener('mouseout', function(event) {
      clearTimeout(showPopoverTimeout);
      var id = event.currentTarget.getAttribute("modalaId");
      document.getElementById(id+"_popover").style.display = "none";
      document.getElementById(id+"_popover").style.opacity = "0";
      document.getElementById(id+"_popover").style.zIndex = "";
    }, false);
  }

  var svgContainer = document.getElementById('7batzarraSvg');
  var svgURL = '/img/zazpi-batzarra-irudia.svg';

  fetch(svgURL)
      .then(function(response) {
          return response.text();
      })
      .then(function(svgData) {
          svgContainer.innerHTML = svgData;
      });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const modalaId = urlParams.get('modala');
  if(modalaId) {
    modalaIreki(modalaId);
  }
}

function modalaIreki(modalaId) {
  debugger;
  const body = document.querySelector('body'); 
  document.getElementById("modala").style.display = 'flex';
  document.getElementById(modalaId).style.display = 'block';
  clearTimeout(showPopoverTimeout);
  body.classList.add('modal-open');
  let modalaKolorea = document.getElementById(modalaId).getAttribute("modalaKolorea")?? event.target.parentNode.getAttribute("modalaKolorea");
  document.getElementById("modala").classList.add(modalaKolorea);
}

function modalaItxi() {
  document.getElementById("modala").scrollTop = 0;
  document.getElementById("modala").style.display = 'none';
  document.getElementById("modala").className = "modal";
  const body = document.querySelector('body');
  body.classList.remove('modal-open');
  youtubePlayers.forEach(element => {
    element.stopVideo();
  });
  var modalContents = document.getElementsByClassName("modalContent");
  for (var i = 0; i < modalContents.length; i++) {
    modalContents[i].style.display = 'none';
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const modalaId = urlParams.get('modala');
  if(modalaId) {
    window.location.assign('/eu');
  }
}

//Swiper
var swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination, EffectCoverflow ],
  effect: "coverflow",
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  allowTouchMove: false,
  spaceBetween: 200,
  coverflowEffect: {
    rotate: 5,
    stretch: 40,
    depth: 50,
    modifier: 1,
    scale: 1,
    slideShadows: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
},
});

sliderDatak.forEach(element => {
  const startDate = new Date(element.hasi);
  const endDate = new Date(element.bukatu);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  const currentDate = new Date();
  const isBetweenDates = currentDate >= startDate && currentDate <= endDate;
  if(isBetweenDates) {
    swiper.slideTo(element.zenbakia);
  }
});

const burguer = document.querySelector('#menuResponsive');
const menu = document.querySelector('#menuResponsive .nav');
if(burguer) {
  burguer.addEventListener('click', e => {
      burguer.classList.toggle('active');
      menu.classList.toggle('open');
      });
}
const overlay = document.querySelector('.overlay');
const close = document.querySelector('.close');
if(close) {
  close.addEventListener('click', e => {
      overlay.classList.toggle('active');
      modal.classList.toggle('active');
      });
}

//Go To Top button
addEventListener("scroll", (event) => {
  scrollFunction();
});

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("topBtn").style.display = "block";
  } else {
      document.getElementById("topBtn").style.display = "none";
  }
};

function goToTop() {
  window.scrollTo({top: 0, behavior: "smooth"});
};

const mqList = window.matchMedia("(max-width: 900px)");

// If media query matches on load
if (mqList.matches) {
  document.querySelector('.non').setAttribute('data-aos-delay', 0);
  document.querySelector('.nora').setAttribute('data-aos-delay', 0);
}

// If media query matches after resize
mqList.addEventListener("change", function(mql) { // Add the event name as the first argument
  if (mql.matches) {
    document.querySelector('.non').setAttribute('data-aos-delay', 0);
    document.querySelector('.nora').setAttribute('data-aos-delay', 0);
  }
});

var youtubePlayers = [];

function initYoutubePlayer(element) {
  const videoId = element.dataset.videoId;
  const player = new YT.Player(element, {
    videoId: videoId,
    playerVars: {
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  });
  element.dataset.playerId = player.getIframe().id;
  youtubePlayers.push(player);
}

const lazyYoutubeObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      initYoutubePlayer(entry.target);
      lazyYoutubeObserver.unobserve(entry.target);
    }
  });
});

const lazyYoutubeElements = document.querySelectorAll('.lazy-youtube');
lazyYoutubeElements.forEach((element) => {
  lazyYoutubeObserver.observe(element);
});
